export const environment = {
    environment: 'qa',
    production: true,
    server_url: 'https://qa.favrit.com/ws/ordr-service',
    onboarding: 'https://onboarding.qa.favrit.com',
    payment: 'https://payment.qa.favrit.com',
    productlib: 'https://qa.favrit.com/ws/product-lib/public-api',
    accounting_service: 'https://qa.favrit.com/ws/accounting-service',
    authenticationService: 'https://qa.favrit.com/ws/authentication-service',
    epos_url: 'https://epos.qa.favrit.com',
    unibill_host: 'https://bill.qa.favrit.com',
    enable_auth: true,
    enable_sentry: false,
    versionCheckURL: null,
    app_base: 'https://qa.favrit.com',
    cloudinary_cloud_name: 'dkjgwja0c',
    cloudinary_api_key: '824345894193677',
    amplitude_api_key: '5b97feb3f1a0801ae8c7561dcd467ef3',
    amplitude_api_key_reporting: '3d54a4ff7974bd7d4da88c6cacfef473',
    fortnox_authorization_link:
        'https://apps.fortnox.se/oauth-v1/auth?client_id=OuY5UKikqqvQ&redirect_uri=https://qa.favrit.com/ws/accounting-service/public/integration/accounting/fortnox&scope=bookkeeping%20archive%20connectfile%20costcenter&state=STATE_VALUE&access_type=offline&response_type=code',
    visma_authorization_link:
        'https://identity-sandbox.test.vismaonline.com/connect/authorize?client_id=ordr&redirect_uri=https://qa.favrit.com/ws/accounting-service/public/integration/e-accounting&scope=ea:api%20offline_access%20ea:accounting&state=STATE_VALUE&response_type=code&prompt=login&acr_values=service:44643EB1-3F76-4C1C-A672-402AE8085934+forceselectcompany:true',
    visma_e_ekonomi_authorization_link:
        'https://identity-sandbox.test.vismaonline.com/connect/authorize?client_id=ordr1&redirect_uri=https://qa.favrit.com/ws/accounting-service/public/integration/accounting/visma_e_ekonomi&scope=ea:api%20offline_access%20ea:accounting&state=STATE_VALUE&response_type=code&prompt=login&acr_values=service:44643EB1-3F76-4C1C-A672-402AE8085934+forceselectcompany:true',
    fiken_authorization_link:
        'https://fiken.no/oauth/authorize?response_type=code&client_id=ZKg3tqBMYl7mncF587427763359410028&redirect_uri=https://qa.favrit.com/ws/accounting-service/public/integration/accounting/fiken&state=STATE_VALUE',
    softrig_authorization_link:
        'https://test-login.softrig.com/connect/authorize?client_id=80a710ba-23c0-4603-81ea-c2957a47a92a&redirect_uri=https://qa.favrit.com/ws/accounting-service/public/integration/accounting/softrig&response_type=code&prompt=login&scope=AppFramework offline_access Accounting.Admin&state=STATE_VALUE',
    planday_authorization_link:
        'https://id.planday.com/connect/authorize?client_id=c0136995-a66d-4110-bd35-5adfc7e72002&response_type=code&redirect_uri=https://qa.favrit.com/ws/accounting-service/public/integration/accounting/planday&scope=openid department:create department:delete department:read department:update revenue:delete revenue:read revenue:set offline_access&state=STATE_VALUE',
    accounting_api_service: 'https://qa.favrit.com/ws/accounting-api-service',
    sage_authorization_link:
        'https://www.sageone.com/oauth2/auth/central?filter=apiv3.1&response_type=code&client_id=51e488ec-2977-46ff-bfcb-b783dd07f6a4/44debd7b-92db-4cd1-8139-a96c5c69b888&redirect_uri=https://qa.favrit.com/ws/accounting-service/public/integration/accounting/sage&scope=full_access&state=STATE_VALUE',
    quickbooks_authorization_link:
        'https://appcenter.intuit.com/connect/oauth2?client_id=ABKof3L7VLYO5KMTUMmJTvgMUZIjwggOyBeCMr2XLRuoedxH47&response_type=code&scope=com.intuit.quickbooks.accounting&redirect_uri=https://qa.favrit.com/ws/accounting-service/public/integration/accounting/quickbooks&state=STATE_VALUE',
    xero_authorization_link:
        'https://login.xero.com/identity/connect/authorize?response_type=code&client_id=1BC519469895482986B2F5AA64C078E1&redirect_uri=https://qa.favrit.com/ws/accounting-service/public/integration/accounting/xero&scope=openid profile email offline_access accounting.transactions accounting.settings accounting.attachments&state=STATE_VALUE'
};
